/* Page4.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.page-4 {
  display: flex;
  flex-direction: column;  /* Stack elements vertically */
  align-items: center;
}

.testimonial-slider {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  max-width: 900px; /* Keeps the slider from becoming too wide on larger screens */
}

.header4 {
  width: 100%;  /* Take full width */
  text-align: center; /* Center the title */
  margin-bottom: 20px; /* Add space between header and slider */
}

.title4 {
  padding: 1%;
  width: 80%;
  margin: 0 auto;
  /* font-size: 2vw; */
  font-weight: bold;
  color: #000000;
  text-align: center;
  padding: 1rem 0; /* Add vertical padding */
  /* font-size: 2.5vw; */
}

.testimonial-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  /* border: 1px solid #ce5e11; */
  background-color: rgb(247, 247, 198);
  border-radius: 5%;
  /* margin: -2rem; Add margin between slides */
}

.testimonial-image-name {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.testimonial-comment {
  font-size: 1.2em;
  margin-top: 10px;
  width: 100%; /* Use this to control the size of the comment box */
  padding: 20px;
  background-color: #f9f9f9;
  border: none;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .testimonial-slider {
    max-width: 600px; /* Keeps the slider from becoming too wide on larger screens */
  }

  .title4 {
    font-size: 4vw; 
  }
  
  .page-4 {
    padding: 1rem;
  }
  
  .move {
    margin-top: 8%;
    padding-top: 5%;
  }
}
