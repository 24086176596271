.content2 {
  flex: 1 0 50%;
  display: flex;
  flex-direction: column;
  color: #000000;
  margin-left: 3%;
  padding-top: 5%;
}
.page-2 {
  position: relative; /* Ensure that top property works */
  /* transform: translateY(+5%); Vertically center the element */
}
.content2 div {
  flex: 1; /* Each div takes equal space */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2%;
}
.details2 {
  padding-top: 5%;

  flex: 1 0 40%;
  position: relative; /* Necessary for absolutely positioned children */
  flex-direction: column;
}
.details2 div {
  flex: 1; /* Each div takes equal space */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4%;
  border: 1px solid #ce5e11;
  border-radius: 15px;
  margin-right: 2%;
  margin-bottom: 1%;
}
.title2 {
  font-size: 2vw;
}
@media (max-width: 600px) {
  .page-2 {
    position: relative; /* Ensure that top property works */
    transform: translateY(+3%); /* Vertically center the element*/
    padding-bottom: 5%;
  }
}

.container2 {
  display: flex;
  flex-direction: row;
}
.title2 {
  padding: 1%;
  width: 80%;
  margin: 0 auto;
  font-size: 2vw;
  font-weight: bold;
  color: #000000;
  text-align: center;
}

@media (max-width: 600px) {
  .title2 {
    font-size: 5vw;
  }
}
@media (min-width: 640px) and (max-width: 1000px) {
  .title2 {
    font-size: 3vw;
  }
}
@media (max-width: 600px) {
  .container2 {
    flex-direction: column;
    align-items: center; /* Center items horizontally */
  }
}
