/* Navigation.css */
.navigation {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px; /* Increased padding for height */
  background-color: #0e85d1;
}

.nav-link {
  text-decoration: none;
}

button {
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  margin: 0 10px;
  color: white;
}

.nav-icon {
  display: none;
  width: 32px; /* Increase icon size */
  height: 32px;
}

.nav-text {
  display: block;
  font-size: 18px; /* Adjust text size */
}

@media (max-width: 600px) {
  .navigation {
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px; /* Ensure the increased padding is consistent */
  }

  .nav-icon {
    display: block;
    width: 55px; /* Further increase icon size for mobile */
    height: 55px;
  }

  .nav-text {
    display: none;
  }

  button {
    flex: 0 0 45%;
    font-size: 18px; /* Adjust button text size for mobile */
    justify-content: center; /* Center the button content vertically */
  }
}
