.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  width: 100%;
  background-color: #add8e6; /* Example color, adjust as needed */
}

main {
  flex: 1; /* Allows the main content to expand and take up available space */
  width: 100%;
}

footer {
  width: 100%;
  background-color: #f1f1f1; /* Example color, adjust as needed */
  padding: 10px;
  text-align: center;
}

body {
  overflow-x: hidden;
}

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
}

.Landing,
.Navigation,
.Navpages {
  width: 100%;
}
