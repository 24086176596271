.marquee-container {
  overflow: hidden;
}

.marquee {
  display: inline-block;
  white-space: nowrap;
  animation-timing-function: linear;
}

.notification-item {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 0 1rem 0 0;
  border-radius: 5px;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  } /* Start from right */
  100% {
    transform: translateX(-100%);
  } /* End completely offscreen left */
}
